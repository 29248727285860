import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { GlobalContext } from "../GlobalContext"
import Card from "../ui-elements/Card"
import RedAlert from "../ui-elements/RedAlert"
import YellowAlert from "../ui-elements/YellowAlert"
import { getDate } from "../utils/getDate"
import FormatQuestionAnswer from "./FormatQuestionAnswer"
import CloudformationOutputs from '../CloudformationOutputs.json'
import FormatQuestionAnsweredBy from "./FormatQuestionAnsweredBy"
import { findNextQuestion } from "../utils/findNextQuestion"
import { checkIfAnswerIsLocked } from "../utils/checkIfAnswerIsLocked"

type Props = {
  questionAnswer: ObjectStringKeyAnyValue
  checkPeriodId: string | undefined
  question: ObjectStringKeyAnyValue
  sortedQuestionAnswers: Array<ObjectStringKeyAnyValue>
}

function QuestionSummaryDetail({
  questionAnswer,
  checkPeriodId,
  question,
  sortedQuestionAnswers
}: Props) {

  const {
    tableData,
    currentOrganisation,
    sendMessageToWebsocket
  } = useContext(GlobalContext)
  const navigate = useNavigate()

  const [isQuestionLocked, setIsQuestionLocked] = useState(false)
  const [lockedUntilText, setLockedUntilText] = useState('')
  const numberOfAlerts = questionAnswer && questionAnswer['AnswerAlerts'] ? Object.values(questionAnswer['AnswerAlerts']).filter((alert: any) => alert['actionToTake'] !== 'followUpQuestion').length : null

  useEffect(() => {
    checkIfAnswerIsLocked(questionAnswer, setIsQuestionLocked, setLockedUntilText)
    const intervalId = setInterval(() => {
      checkIfAnswerIsLocked(questionAnswer, setIsQuestionLocked, setLockedUntilText)
    }, 1000)
    return () => clearInterval(intervalId)
  }, [])

  if (!question) return null

  return <div className={`flex flex-row items-start`}>

    {(question.parentQuestionId || questionAnswer.repeatAnswerAfter) &&
      <div className={`mr-2 p-1 border-b-4 border-dotted border-white w-8`}>&nbsp;</div>}


    <div
      className={`text-left w-full rounded bg-white flex flex-row 
        ${isQuestionLocked ? ` opacity-60 ` : ` hover:opacity-80 cursor-pointer `}
        `}
      onClick={() => {
        if (!isQuestionLocked) {
          navigate(`/checklists/${checkPeriodId}/${questionAnswer.Id}`)
        }
      }}>



      {questionAnswer && !isQuestionLocked && !questionAnswer.Pending &&
        <div className={`w-16 bg-brandblue text-white rounded-l py-4 px-2 flex flex-col justify-center items-center text-2xl font-bold`}>✓</div>}

      {questionAnswer && !isQuestionLocked && questionAnswer.Pending &&
        <div className={`w-16 bg-red-400 text-white rounded-l py-4 px-2 flex flex-col justify-center items-center text-2xl font-bold`}>?</div>}

      {questionAnswer && isQuestionLocked &&
        <div className={`w-16 bg-gray-600 text-white rounded-l py-4 px-2 flex flex-col justify-center items-center text-2xl font-bold`}>🔐</div>}


      <div className={`w-full flex flex-row justify-between items-center`}>

        <div className={`w-full p-2 text-sm flex flex-col justify-center items-start gap-1`}>

          {/* <div className={`text-xs`}>
            <p>This answer: {questionAnswer.Id}</p>
            <p>Next answer: {findNextQuestion(questionAnswer.Id, sortedQuestionAnswers)}</p>
          </div> */}

          {question.QuestionFormat !== 'FridgeTemperature' && <h5 className={`text-lg font-righteous text-brandblue`}>{question.QuestionText}</h5>}

          {question.QuestionFormat === 'FridgeTemperature' && <h5 className={`text-lg font-righteous text-brandblue`}>Temperature check for fridge: {question.FridgeId} </h5>}


          {questionAnswer && questionAnswer.repeatAnswerAfter && !isQuestionLocked && <p className={`text-xs text-gray-600 italic`}>🔁 Repeated after {questionAnswer.repeatAnswerAfter < 60 ? ` ${questionAnswer.repeatAnswerAfter} minutes ` : ` ${Math.round(questionAnswer.repeatAnswerAfter / 60 * 10) / 10} hours `}</p>}


          {lockedUntilText && <p className={`text-xs text-gray-600 italic`}>Question locked. Answer in: {lockedUntilText}</p>}



          {!questionAnswer && question.QuestionInstructions && <p className={`text-xs text-gray-600 italic`}><span className={`font-bold`}>Instructions</span>: {question.QuestionInstructions} </p>}

          {questionAnswer && !questionAnswer.Pending && <div className={`w-full flex flex-col items-start text-left gap-1`}>

            <FormatQuestionAnswer
              questionAnswer={questionAnswer}
              isTemperature={question.QuestionFormat === 'FridgeTemperature' ? true : false}
            />

            <p className={`text-xs text-gray-600 italic`}><span className={`font-bold`}>Answered by</span>:
              <FormatQuestionAnsweredBy
                questionAnswer={questionAnswer}
              />
            </p>


            <div className={`w-full flex flex-row gap-2 items-start justify-start`}>
              {numberOfAlerts ?
                <RedAlert
                  icon={true}
                  fullwidth={false}
                  alignment={`left`}
                  size={`small`}
                >
                  {numberOfAlerts} alert{numberOfAlerts > 1 ? 's' : ''}
                </RedAlert> : ''}


              {questionAnswer.AnswerNotes && Object.keys(questionAnswer.AnswerNotes).length > 0 &&
                <YellowAlert
                  icon={true}
                  fullwidth={false}
                  alignment={`left`}
                  size={`small`}
                >
                  {Object.keys(questionAnswer.AnswerNotes).length} user note{Object.keys(questionAnswer.AnswerNotes).length > 1 ? 's' : ''} added
                </YellowAlert>}

            </div>

          </div>}




          {/* <div className={`text-xs text-gray-600 italic`}>
        <p>{question.Id}</p>
        <p>{nextQuestionId}</p>
      </div> */}

        </div>

        {questionAnswer && questionAnswer.ImageFileName && currentOrganisation &&
          <img src={`https://${CloudformationOutputs.ImageBucketName}.s3.eu-west-1.amazonaws.com/${currentOrganisation}/${questionAnswer.ImageFileName}`} alt=''
            className={`rounded bg-gray-400 min-h-8 min-w-8 max-h-20 m-2`}
          />}
      </div>

    </div>
  </div>
}


export default QuestionSummaryDetail