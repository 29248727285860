
import React, { useContext, Children, useState, useEffect } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Button from '../formfields/Button'
import Spinner from '../ui-elements/Spinner'
import FooterNavButtons from '../shared-components/FooterNavButtons'
import HeaderDetails from '../shared-components/HeaderDetails'
import RedAlert from '../ui-elements/RedAlert'
import Modal from '../ui-elements/Modal'
import { subscribe } from '../utils/pubsub'
import { useVideo } from '../context/video/VideoContext'

// question types
import QuestionYesOrNo from './QuestionYesOrNo'
import QuestionText from './QuestionText'
import QuestionFridgeTemperature from './QuestionFridgeTemperature'
import QuestionPhoto from './QuestionPhoto'
import QuestionSelectMultiple from './QuestionSelectMultiple'
import QuestionSelectOne from './QuestionSelectOne'
import FormatQuestionAnswer from '../dashboard/FormatQuestionAnswer'
import { b64toBlob } from '../utils/b64toBlob'
import QuestionConfirmPage from './QuestionConfirmPage'
import { findNextQuestion } from '../utils/findNextQuestion'
import { getChecklistProgress } from '../utils/getChecklistProgress'
import { sortAllQuestionAnswersForCheckPeriod } from '../utils/sortAllQuestionAnswersForCheckPeriod'
import { checkIfAnswerIsLocked } from '../utils/checkIfAnswerIsLocked'
import Card from '../ui-elements/Card'



type Props = {
  children?: any
}

function QuestionPage({
  children
}: Props) {

  const {
    tableData,
    sendMessageToWebsocket,
    isVideoMode,
    setIsVideoMode,
    setIsVideoPlaying,
    loggedIn,
    userData,
    isSignoffRequired,
    checkPeriodIdsForActiveCheckists
  } = useContext(GlobalContext)

  const navigate = useNavigate()
  const { answerId, checkPeriodId } = useParams()
  const [isQuestionLocked, setIsQuestionLocked] = useState(false)
  const [lockedUntilText, setLockedUntilText] = useState('')
  const [savingProgress, setSavingProgress] = useState(false)
  const [isAnswerPending, setIsAnswerPending] = useState(false)
  const [thisQuestion, setThisQuestion] = useState<null | ObjectStringKeyAnyValue>(null)
  // const [questionId, setQuestionId] = useState<null | string>(null)
  const [questionAnswerText, setQuestionAnswerText] = useState<null | string | Array<string>>(null)
  const [questionAnswer, setQuestionAnswer] = useState<null | ObjectStringKeyAnyValue>(null)
  const [answerNote, setAnswerNote] = useState<null | string>(null)
  const [answerPhoto, setAnswerPhoto] = useState<null | string>(null)
  const [subheadingText, setSubheadingText] = useState<string>('')
  const [isAnswerManuallyEdited, setIsAnswerManuallyEdited] = useState(false)
  const [errorMessage, setErrorMessage] = useState<null | string>(null)
  const [nextButtonText, setNextButtonText] = useState('')
  const [nextButtonAction, setNextButtonAction] = useState<null | AnyFunction>(null)
  const [nextQuestionId, setNextQuestionId] = useState<null | string>(null)
  // const [thisCheckPeriodSignoff, setThisCheckPeriodSignoff] = useState<ObjectStringKeyAnyValue | null>(null)
  const [checklistProgress, setChecklistProgress] = useState<null | ObjectStringKeyAnyValue>(null)
  const [jpegString, setJpegString] = useState<string | null>(null)
  const { captureAsJPEGString, straightenedImageUrl, straightenedImageNoQRUrl, QRCodesPresent,
    setQRCodesPresent, clearCameraStateVariables } = useVideo()

  const checklistId = checkPeriodId && tableData && tableData.CheckPeriods && tableData.CheckPeriods[checkPeriodId] && tableData.CheckPeriods[checkPeriodId]['ChecklistId'] ? tableData.CheckPeriods[checkPeriodId]['ChecklistId'] : null

  const thisCheckPeriodSignoff = checkPeriodId && tableData && tableData.CheckPeriods && tableData.CheckPeriods[checkPeriodId] && tableData.CheckPeriods[checkPeriodId]['Signoff'] ? tableData.CheckPeriods[checkPeriodId]['Signoff'] : null


  useEffect(() => {
    if (questionAnswer) {
      checkIfAnswerIsLocked(questionAnswer, setIsQuestionLocked, setLockedUntilText)
      const intervalId = setInterval(() => {
        checkIfAnswerIsLocked(questionAnswer, setIsQuestionLocked, setLockedUntilText)
      }, 1000)
      return () => clearInterval(intervalId)
    }
  }, [questionAnswer])


  useEffect(() => {
    // code to run when the user navigates away from the page
    return () => {
      setIsVideoMode(false)
      setIsVideoPlaying(false)
      // console.log("leaving page")
    }
  }, [])

  useEffect(() => {
    handleSetThisQuestion()

    if (tableData && checkPeriodId) {
      const progress = getChecklistProgress(checkPeriodId, tableData)
      if (progress) {
        setChecklistProgress(progress)
      }
    }

    if (thisQuestion && checkPeriodId && tableData) {
      const allQuestionAnswersArray = sortAllQuestionAnswersForCheckPeriod(checkPeriodId, tableData)
      const nextQuestion = findNextQuestion(answerId, allQuestionAnswersArray)
      nextQuestion && setNextQuestionId(nextQuestion)
    }

  }, [tableData, answerId, thisQuestion, checkPeriodId])



  useEffect(() => {
    // clear error message when answer gets updated
    setErrorMessage(null)
  }, [questionAnswerText])

  useEffect(() => {
    setNavButtons()
  }, [checkPeriodId, questionAnswerText, isAnswerPending, nextQuestionId, answerPhoto, thisQuestion])


  const setNavButtons = () => {
    //console.log(`🦺 setting nav buttons`)
    if (checkPeriodId) {
      if (isAnswerPending && (questionAnswerText || answerPhoto)) {
        // if the answer is pending, and there is an answer to save
        setNextButtonText('Save answer')
        setNextButtonAction(() => () => validate())
      }
      else if (!isAnswerPending && nextQuestionId && nextQuestionId !== 'AllQuestionsComplete') {
        // if the question has been answered but there are more questions
        setNextButtonText('Next question')
        setNextButtonAction(() => () => navigate(`/checklists/${checkPeriodId}/${nextQuestionId}`))
      }
      else if (isSignoffRequired && !isAnswerPending && !thisCheckPeriodSignoff && nextQuestionId === 'AllQuestionsComplete') {
        // if all questions are answered and signoff is required
        setNextButtonText('Sign off')
        setNextButtonAction(() => () => navigate(`/checklists/${checkPeriodId}/complete-checklist`))
      }
      else if (!isSignoffRequired && !isAnswerPending && nextQuestionId === 'AllQuestionsComplete') {
        // if all questions are answered and no signoff required
        setNextButtonText('Done')
        setNextButtonAction(() => () => navigate(`/checklists/`))
      } else if (checkPeriodId && !answerPhoto && isAnswerPending && thisQuestion?.QuestionFormat === 'Photo') {
        setNextButtonText('📸 Take photo')
        setNextButtonAction(() => () => capturePhoto())
      } else {
        setNextButtonText('')
        setNextButtonAction(null)
      }
    } else {
      setNextButtonText('')
      setNextButtonAction(null)
    }
  }


  const handleSetThisQuestion = () => {
    if (
      answerId &&
      tableData &&
      tableData.ChecklistQuestions &&
      tableData.ChecklistAnswers
    ) {

      if (tableData.ChecklistAnswers[answerId]) {
        let subtitleText = ''
        const questionAnswerObject = tableData.ChecklistAnswers[answerId]
        setQuestionAnswer(questionAnswerObject)
        setQuestionAnswerText(questionAnswerObject.Answer)
        setIsAnswerPending(questionAnswerObject.Pending)
        const questionIdForThisAnswer = questionAnswerObject.QuestionId
        if (tableData.ChecklistQuestions[questionIdForThisAnswer]) {
          const questionForThisAnswer = tableData.ChecklistQuestions[questionIdForThisAnswer]
          setThisQuestion(questionForThisAnswer)

          // set the subheading text if its a follow up
          if (questionForThisAnswer.parentQuestionId && tableData.ChecklistQuestions[questionForThisAnswer.parentQuestionId]) {
            const parentQuestionText = tableData.ChecklistQuestions[questionForThisAnswer.parentQuestionId].QuestionText
            subtitleText = `${subtitleText} Follow up question for: ${parentQuestionText ? `"${parentQuestionText}"` : `ID #:${questionForThisAnswer.parentQuestionId}`}`
          }
        }
        // set the subheading text if its a delayed question
        if (questionAnswerObject.repeatAnswerAfter) {
          subtitleText = `${subtitleText} 🔁 Repeated after ${questionAnswerObject.repeatAnswerAfter < 60 ? ` ${questionAnswerObject.repeatAnswerAfter} minutes ` : ` ${Math.round(questionAnswerObject.repeatAnswerAfter / 60 * 10) / 10} hours `}`
        }
        setSubheadingText(subtitleText)
      }
    }
  }


  const handleChangeSelectMultiple = (value: string) => {
    let newAnswerArray: string[]

    // if existing questionAnswer is not an array, start a new array
    if (questionAnswerText && questionAnswerText instanceof Array) {
      newAnswerArray = [...questionAnswerText]
    } else {
      newAnswerArray = []
    }
    // add the value if it doesn't already exist
    // remove the value if it does exist already
    if (newAnswerArray.includes(value)) {
      newAnswerArray = newAnswerArray.filter((option) => option !== value)
    } else {
      newAnswerArray.push(value)
    }
    setQuestionAnswerText(newAnswerArray)
  }


  const validate = async () => {
    if (!questionAnswerText && thisQuestion?.QuestionFormat !== 'Photo') {
      setErrorMessage('Please select or enter a valid answer')
    }
    else if (!answerPhoto && thisQuestion?.QuestionFormat === 'Photo') {
      setErrorMessage('Please take a picture')
    } else {
      await saveAnswer()
    }
  }

  const saveNote = async () => {
    console.log(`saving note "${answerNote}" for answer id: ${answerId}`)
    if (answerId && answerNote) {
      setSavingProgress(true)
      const payload: ObjectStringKeyAnyValue = {
        action: "checklistsClientApp",
        subAction: "saveAnswerNote",
        answerId: `${answerId}`,
        answerNote: answerNote
      }
      // if a user is logged in as a guest, add their name to the data
      if (loggedIn === 'guest') {
        payload['GuestUserName'] = userData.name
      }
      sendMessageToWebsocket(JSON.stringify(payload))
      const unsubscribe = subscribe("tableUpdateItemModified", data => {
        if (data.newRecord && data.newRecord.Id === answerId) {
          setSavingProgress(false)
          setAnswerNote(null)
          unsubscribe()
        }
      })
    }
  }


  const saveAnswer = async () => {
    setErrorMessage(null)
    if (answerId) {
      setSavingProgress(true)

      // send answer to websocket
      const payload: ObjectStringKeyAnyValue = {
        action: "checklistsClientApp",
        subAction: "saveAnswerToQuestion",
        answerId: `${answerId}`,
        values: {
          Answer: questionAnswerText
        }
      }
      // add an answer note if there is one
      if (answerNote) payload.values['AnswerNote'] = answerNote

      // if a user is logged in as a guest, add their name to the data
      if (loggedIn === 'guest') {
        payload['GuestUserName'] = userData.name
      }

      // if its a fridge temp, include if it's manually edited 
      if (thisQuestion?.QuestionFormat === 'FridgeTemperature') {
        payload.values['isAnswerManuallyEdited'] = isAnswerManuallyEdited
      }

      // console.log(payload)
      sendMessageToWebsocket(JSON.stringify(payload))

      // navigate back once the answer is saved (unless its a photo upload)
      const unsubscribe = subscribe("tableUpdateItemModified", data => {
        if (data.newRecord && data.newRecord.Id === answerId) {
          !answerPhoto && setSavingProgress(false)
          setJpegString(null)
          setQuestionAnswerText(null)
          setAnswerNote(null)
          // !answerPhoto && navigate(`/checklists/${checklistId}`)
          unsubscribe()
        }
      })

      // if there is a photo, upload it
      if (answerPhoto) {
        const timestamp = `${Date.now()}`
        let fileName = `${answerId}-${timestamp}.jpg`
        await handleImageUpload(answerId, answerPhoto, fileName, timestamp)
      }

    } else {
      setErrorMessage('Sorry - could not save this answer')
    }
  }


  const handleImageUpload = async (answerId: string, dataString: string, fileName: string, timestamp: string) => {
    const payload = JSON.stringify({
      action: "checklistsClientApp",
      subAction: "requestPresignedUrl",
      fileName: fileName,
      waitingForUploadUrlId: timestamp
    })
    sendMessageToWebsocket(payload)
    const response = await new Promise<void>((resolve, reject) => {
      const unsubscribe = subscribe("returnPresignedUrl", async data => {
        if (data.waitingForUploadUrlId === timestamp) {
          unsubscribe()
          const prefix = 'data:image/jpeg;base64,'
          const fileBody = dataString!.split(prefix)[1]
          const file = new File([b64toBlob(fileBody)], timestamp, { type: "image/jpeg" })
          await fetch(data.signedUrl, {
            method: 'PUT',
            body: file
          })
            .then(response => {
              // send answer to websocket
              const payload = JSON.stringify({
                action: "checklistsClientApp",
                subAction: "saveAnswerToQuestion",
                answerId: `${answerId}`,
                values: {
                  ImageFileName: data.imageFileName
                }
              })
              sendMessageToWebsocket(payload)

              // navigate back once the answer is saved (unless its a photo upload)
              const unsubscribe = subscribe("tableUpdateItemModified", data => {
                if (data.newRecord && data.newRecord.Id === answerId) {
                  setSavingProgress(false)
                  setAnswerPhoto(null)
                  // navigate(`/checklists/${checklistId}`)
                  unsubscribe()
                }
              })

              return (response)
            }).catch((error) => {
              console.error('Error:', error)
            })
        }
      })
    })
  }

  const retakePhoto = () => {
    setAnswerPhoto && setAnswerPhoto(null)
    setJpegString(null)
    setIsVideoPlaying(true)
    setIsVideoMode(true)
    setQRCodesPresent(null)
    clearCameraStateVariables()
    setQuestionAnswerText(null)
  }


  const capturePhoto = () => {
    const jpgData = captureAsJPEGString(600)
    setAnswerPhoto && setAnswerPhoto(jpgData || null)
    setJpegString && setJpegString(jpgData || null)
    setIsVideoPlaying(false)
    setIsVideoMode(false)
  }



  const resetQuestion = (answerId: string) => {
    setSavingProgress(true)
    const payload = JSON.stringify({
      action: "checklistsClientApp",
      subAction: "resetAnswer",
      answerId: answerId
    })
    sendMessageToWebsocket(payload)
    const unsubscribe = subscribe("resetAnswerResponse", data => {
      if (data.answerId && data.answerId === answerId) {
        setSavingProgress(false)
        navigate(`/checklists/${checkPeriodId}`)
        unsubscribe()
      }
    })
  }


  // console.log(`fridgeid: ${thisQuestion ? thisQuestion.FridgeId : 'unknown'}`)


  return <div
    className={`w-full flex-1 h-full flex flex-col justify-between gap-2`}
  >



    {savingProgress && <Modal
      showCloseButton={true}
      setShowModal={setSavingProgress}
    ><Spinner><p>Saving...</p></Spinner>
    </Modal>}



    <div className={`w-full flex flex-col items-center`}>
      {thisQuestion && thisQuestion.QuestionFormat !== 'FridgeTemperature' &&
        <HeaderDetails
          titleText={` ${thisQuestion ? thisQuestion.QuestionText : 'Unknown Question'}`}
          instructionsText={thisQuestion.QuestionInstructions}
          subheadingText={subheadingText}
        />}


      {thisQuestion && thisQuestion.QuestionFormat === 'FridgeTemperature' && <HeaderDetails
        fridgeId={thisQuestion.FridgeId}
        instructionsText={thisQuestion.QuestionInstructions}
      />}



      {!tableData && <div className={`max-w-3xl w-full p-5 flex flex-col gap-2 items-center justify-center text-center`}><Spinner><p>Loading...</p></Spinner></div>}

      {tableData && <div className={`max-w-3xl w-full p-5 flex flex-col gap-2 items-center justify-center text-center`}>




        {!thisQuestion && <div>
          <RedAlert>Invalid question</RedAlert>
        </div>}


        {thisQuestion && !isAnswerPending &&
          <QuestionConfirmPage
            question={thisQuestion}
            answerNote={answerNote}
            setAnswerNote={setAnswerNote}
            saveNote={saveNote}
            setJpegString={setJpegString}
            resetQuestion={resetQuestion}
            setAnswerPhoto={setAnswerPhoto}
          />}


        {isQuestionLocked && thisQuestion && <Card>
          <div className={`flex flex-col items-center`}>
          <p className={`text-4xl`}>🔐</p>
          <h5 className={`font-righteous text-brandblue text-lg`}>This question is locked</h5>
          <p className={``}>{lockedUntilText}</p>
          </div>
        </Card>}

        {!isQuestionLocked && thisQuestion && isAnswerPending &&
          <div className={`w-full mb-4`}>

            {thisQuestion.QuestionFormat === 'YesOrNo' && <QuestionYesOrNo
              setQuestionAnswer={setQuestionAnswerText}
              questionId={thisQuestion.Id}
              checklistId={checklistId}
              defaultValue={questionAnswerText !== null ? questionAnswerText : ''}
            />}

            {thisQuestion.QuestionFormat === 'Text' && <QuestionText
              setQuestionAnswer={setQuestionAnswerText}
              questionId={thisQuestion.Id}
              checklistId={checklistId}
              defaultValue={questionAnswerText !== null ? questionAnswerText : ''}
            />}

            {thisQuestion.QuestionFormat === 'Photo' && <QuestionPhoto
              answerPhoto={answerPhoto}
              setAnswerPhoto={setAnswerPhoto}
              questionId={thisQuestion.Id}
              checklistId={checklistId}
              answerNote={answerNote}
              setAnswerNote={setAnswerNote}
              defaultValue={questionAnswerText !== null ? questionAnswerText : ''}
              jpegString={jpegString}
              setJpegString={setJpegString}
            />}


            {thisQuestion.QuestionFormat === 'FridgeTemperature' && <QuestionFridgeTemperature
              questionId={thisQuestion.Id}
              checklistId={checklistId}
              defaultValue={questionAnswerText !== null ? questionAnswerText : ''}
              fridgeId={thisQuestion.FridgeId}
              questionAnswer={questionAnswerText}
              setQuestionAnswer={setQuestionAnswerText}
              answerNote={answerNote}
              setAnswerNote={setAnswerNote}
              answerPhoto={answerPhoto}
              setAnswerPhoto={setAnswerPhoto}
              isAnswerManuallyEdited={isAnswerManuallyEdited}
              setIsAnswerManuallyEdited={setIsAnswerManuallyEdited}
              jpegString={jpegString}
              setJpegString={setJpegString}
            />}

            {thisQuestion.QuestionFormat === 'SelectMultiple' && <QuestionSelectMultiple
              handleChangeSelectMultiple={handleChangeSelectMultiple}
              questionId={thisQuestion.Id}
              checklistId={checklistId}
              defaultValue={questionAnswerText !== null ? questionAnswerText : ''}
              questionOptions={thisQuestion.QuestionOptions}
            />}

            {thisQuestion.QuestionFormat === 'SelectOne' && <QuestionSelectOne
              setQuestionAnswer={setQuestionAnswerText}
              questionId={thisQuestion.Id}
              checklistId={checklistId}
              defaultValue={questionAnswerText !== null ? questionAnswerText : ''}
              questionOptions={thisQuestion.QuestionOptions}
            />}


          </div>}

        {errorMessage && <Modal
        // showCloseButton={true}
        // setShowModal={setErrorMessage}
        // closeButtonValue={null}
        >
          <div className={`flex flex-col gap-4`}>
            <RedAlert icon={true} size={'large'}>{errorMessage}</RedAlert>
            <Button
              onClick={() => setErrorMessage(null)}
              text={'Ok'}
              size={'big'}
            /></div>
        </Modal>}

      </div>}
    </div>


    <FooterNavButtons
      backButtonUrl={`/checklists/${checkPeriodId}/`}
      backButtonText={jpegString ? 'Retake' : 'Back'}
      backButtonAction={jpegString ? retakePhoto : null}
      nextButtonAction={nextButtonAction}
      nextButtonText={nextButtonText}
      answerId={answerId}
    />

  </div>
}
export default QuestionPage