import React, { useEffect, useState, useContext } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import Button from '../formfields/Button'
import { GlobalContext } from '../GlobalContext'
import FooterNavButtons from '../shared-components/FooterNavButtons'
import HeaderDetails from '../shared-components/HeaderDetails'
import Card from '../ui-elements/Card'
import RedAlert from '../ui-elements/RedAlert'
import Spinner from '../ui-elements/Spinner'
import QuestionSummary from './QuestionSummary'
import SummaryCheckWindow from './SummaryCheckWindow'
import { getChecklistProgress } from '../utils/getChecklistProgress'
import { subscribe } from '../utils/pubsub'
import Modal from '../ui-elements/Modal'
import YellowAlert from '../ui-elements/YellowAlert'
import GreenAlert from '../ui-elements/GreenAlert'


type Props = {
    checkPeriodId: string | undefined
    checklistProgress: ObjectStringKeyAnyValue | null
    thisCheckPeriodSignoff: ObjectStringKeyAnyValue | null
}

function ChecklistStatus({
    checkPeriodId,
    checklistProgress,
    thisCheckPeriodSignoff
}: Props) {

    const {
        tableData,
        isSignoffRequired,
        checkPeriodIdsForActiveCheckists,
        sendMessageToWebsocket
    } = useContext(GlobalContext)

    const navigate = useNavigate()


    return <div className={`w-full flex-1 h-full flex flex-col justify-between gap-2`}>

        {!isSignoffRequired && checklistProgress && (checklistProgress.numberOfExpectedAnswers === checklistProgress.numberOfCompletedAnswers) &&
            <GreenAlert icon={true} alignment={'left'} onClick={() => navigate(`/checklists/${checkPeriodId}/complete-checklist`)}>
                Checklist completed
            </GreenAlert>}

        {isSignoffRequired && thisCheckPeriodSignoff && checklistProgress && (checklistProgress.numberOfExpectedAnswers === checklistProgress.numberOfCompletedAnswers) &&
            <GreenAlert icon={true} alignment={'left'} onClick={() => navigate(`/checklists/${checkPeriodId}/complete-checklist`)}>
               Checklist completed and signed off
            </GreenAlert>}


        {isSignoffRequired && !thisCheckPeriodSignoff && checklistProgress && (checklistProgress.numberOfExpectedAnswers === checklistProgress.numberOfCompletedAnswers) &&
            <YellowAlert icon={true} alignment={'left'} onClick={() => navigate(`/checklists/${checkPeriodId}/complete-checklist`)}>
              Checklist signoff required
            </YellowAlert>}

    </div>
}

export default ChecklistStatus