export const findNextQuestion = (currentQuestionAnswerId, allQuestionAnswersArray) => {
  //console.log('🔎 finding next question...')


  // filter out any answers that are not pending
  let availableAnswers = []
  allQuestionAnswersArray.forEach((answer) => {
    if (
      answer.Pending === true
    ) {
      availableAnswers.push(answer)
    }
  })

  // if no current answer is provided, return the first in the list
  if (!currentQuestionAnswerId) {
    return availableAnswers[0].Id
  }

  // if an answer is provided, return the next in the list
  if (currentQuestionAnswerId) {
    const currentAnswerIndex = availableAnswers.findIndex((answer) => answer.Id === currentQuestionAnswerId)

    if (currentAnswerIndex === availableAnswers.length - 1) {
      return 'AllQuestionsComplete'
    } else {
      return availableAnswers[currentAnswerIndex + 1].Id
    }
  }

  return null

  // empty return if parameters are empty
  // if (
  //   !checkPeriodId ||
  //   !tableData ||
  //   !tableData['ChecklistPeriods'] ||
  //   !tableData['ChecklistQuestions'] ||
  //   !tableData['ChecklistAnswers']
  // ) {
  //   console.log('⛔️ error - missing tabledata or checkPeriodId')
  //   return null
  // }


  // const checklistId = tableData['ChecklistPeriods'] &&
  //   tableData['ChecklistPeriods'][checkPeriodId] &&
  //   tableData['ChecklistPeriods'][checkPeriodId].ChecklistId ?
  //   tableData['ChecklistPeriods'][checkPeriodId].ChecklistId :
  //   null
  // if (!checklistId) {
  //   console.log('⛔️ error finding checklist')
  //   return null
  // }


  // // filter and questions for this checklist
  // let remainingQuestionsArray = []
  // Object.values(tableData['ChecklistQuestions']).forEach((question) => {

  //   if (question.ChecklistId === checklistId && question.Id !== currentQuestionId) {
  //     //console.log(`Question ID: ${question.Id}`)

  //     // find all answers for this question
  //     // if any of the answers are no longer pending, set question to answered
  //     let isAnswerPending = false
  //     const answersForThisQuestion = Object.values(tableData['ChecklistAnswers']).filter((answer) => answer.QuestionId === question.Id)
  //     //console.log(answersForThisQuestion)


  //     answersForThisQuestion.forEach((answer) => {
  //       //console.log(`answer: ${answer.Id} ${answer.Pending}`)
  //       if (answer.Pending === true) {
  //         isAnswerPending = true
  //       } else {
  //         isAnswerPending = false
  //       }
  //     })
  //     // add to list of remaining questions
  //     if (isAnswerPending) {
  //       remainingQuestionsArray.push(question)
  //     }
  //   }
  // })

  // if (remainingQuestionsArray.length === 0) {
  //   //console.log(`all complete`)
  //   return 'AllQuestionsComplete'
  // }

  // // sort questions in the array by their order
  // remainingQuestionsArray.sort((a, b) => parseInt(a.QuestionOrder) - parseInt(b.QuestionOrder))
  // const nextQuestionId = remainingQuestionsArray[0].Id

  // //console.log(remainingQuestionsArray)
  // return nextQuestionId
}